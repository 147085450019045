import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NewsService } from '../news.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
import { MyDietRbacCustomService } from 'src/app/common/services/mydiet-rbac.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NewsEditorComponent } from '../news-editor/news-editor.component';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { MyDietCommonService } from 'src/app/common/services/mydiet-common.service';

@Component({
  selector: 'app-system-news',
  templateUrl: './system-news.component.html',
  styleUrls: ['./system-news.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SystemNewsComponent implements OnInit {

  constructor(private newsService: NewsService,
    private toastrService: ToastrService,
    private domSanitizer: DomSanitizer,
    private rbacCustomService: MyDietRbacCustomService,
    private modalService: NgbModal,
    private commonService: MyDietCommonService
  ) { }
  public sortNewsOptions: string[] = ['Newest on top', 'Oldest on top'];
  public sortNewsModel = 'Newest on top';
  public metaData: any;
  public systemNewsData: any;
  public systemNews: any;
  public isSystemNewsAvailable: boolean = true;
  public pageSource: string = 'System News';
  currentUserRole: string;
  isSystemAdmin: boolean = false;
  facilitySelectedHospNews: Subscription;
  isValidRoleAndSubscribed: boolean = false;
  @BlockUI('system-news') blockUI: NgBlockUI;

  ngOnInit(): void {
    let rbacObj = this.rbacCustomService.rbacCustomObj;
    if (rbacObj?.current_role && rbacObj.current_role.role_name) {
      this.currentUserRole = rbacObj.current_role.role_name;
    }

    if (this.currentUserRole === "System Administrator") {
      this.isSystemAdmin = true;
      this.isValidRoleAndSubscribed = true;
    } else {
      this.isSystemAdmin = false;
      this.isValidRoleAndSubscribed = false;
    }

    // this.facilitySelectedHospNews = this.commonService.facilityChange
    // .subscribe(facility => {
    //   if (facility && facility.costCenterId) {
    //     if (facility.subscription && facility.subscription.subscriptionName && this.isSystemAdmin) {

    //     }
    //     else {

    //     }
    //   }
    // });

    this.getAllSystemNews();
  }

  sortNewsChange() {

    if (this.sortNewsModel === "Newest on top") {
      this.systemNews.sort((a: any, b: any) => {
        const dateDiff = new Date(b.submittedDate).getTime() - new Date(a.submittedDate).getTime();
        if (dateDiff !== 0) {
          return dateDiff;
        }
        return b.id - a.id;
      });
    } else {
      this.systemNews.sort((a: any, b: any) => {
        const dateDiff = new Date(a.submittedDate).getTime() - new Date(b.submittedDate).getTime();
        if (dateDiff !== 0) {
          return dateDiff;
        }
        return b.id - a.id;
      });
    }
  }

  getAllSystemNews() {
    this.blockUI.start();
    this.newsService.getAllSystemNews().subscribe({
      next: (res) => {
        this.systemNewsData = res.data;
        this.assignSystemNewsData();
      },
      error: (error) => {
        this.isSystemNewsAvailable = false;
        console.error(error);
        this.blockUI.stop();
        this.toastrService.error('Error loading system news data.');
      }
    });
  }

  assignSystemNewsData() {
    let data = this.systemNewsData;

    let systemNewsArray = [];
    for (let i = 0; i < data.length; i++) {
      let newsObj = {
        id: data[i].id,
        title: data[i].title,
        content: this.domSanitizer.bypassSecurityTrustHtml(data[i].content),
        daywithMonth: this.newsService.getMonthNameFromDate(data[i].submittedDate),
        year: this.newsService.getYearFromDate(data[i].submittedDate),
        expiryDate: data[i].expiryDate,
        submittedDate: data[i].submittedDate
      }
      systemNewsArray.push(newsObj);
      this.systemNews = systemNewsArray;
    }
    if (systemNewsArray && systemNewsArray.length > 0) {
      this.isSystemNewsAvailable = true;
      this.sortNewsChange();
    }
    else {
      this.systemNews = [];
      this.isSystemNewsAvailable = false;
    }
    this.blockUI.stop();
  }

  addSystemNews() {
    if (this.isValidRoleAndSubscribed) {
      let newsEntryModal = this.modalService.open(NewsEditorComponent, {
        backdrop: 'static',
        keyboard: false,
        size: 'lg'
      });

      newsEntryModal.componentInstance.selectedFacilityId = 0;
      newsEntryModal.componentInstance.pageTitle = 'Add System News Headline';
      newsEntryModal.componentInstance.pageAction = 'Add';
      newsEntryModal.componentInstance.id = 0;
      newsEntryModal.componentInstance.validRoleAndSubscribed = this.isValidRoleAndSubscribed;
      newsEntryModal.componentInstance.pageSource = this.pageSource;
      // newsEntryModal.componentInstance.expiryDate = new Date(new Date().setDate(new Date().getDate() + 60));

      newsEntryModal.result.then(res => {
        if (res) {
          this.getAllSystemNews();
        }
      });
    }
    else {
      this.toastrService.error('Selected Facility dont have valid subscription.');
    }
  }

  initiateEdit(selectedNews: any) {
    let newsEntryModal = this.modalService.open(NewsEditorComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    });

    newsEntryModal.componentInstance.selectedFacilityId = 0;
    newsEntryModal.componentInstance.pageTitle = 'Edit System News Headline';
    newsEntryModal.componentInstance.pageAction = 'Edit';
    newsEntryModal.componentInstance.id = selectedNews.id;
    newsEntryModal.componentInstance.pageSource = this.pageSource;
    newsEntryModal.componentInstance.validRoleAndSubscribed = this.isValidRoleAndSubscribed;
    newsEntryModal.componentInstance.quillData = this.domSanitizer.sanitize(SecurityContext.HTML, selectedNews.content)
    newsEntryModal.componentInstance.expiryDate = moment(selectedNews.expiryDate).format('YYYY-MM-DD');
    newsEntryModal.componentInstance.newsTitle = selectedNews.title;

    newsEntryModal.result.then(res => {
      if (res) {
        this.getAllSystemNews();
      }
    });
  }

  ngOnDestroy() {
    if (this.facilitySelectedHospNews) {
      this.facilitySelectedHospNews.unsubscribe();
    }
  }
}
