import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { getEmptyLiveManualResponse, LiveManualResponse, Section } from 'src/app/common/models/manual.model';
import { MyDietCommonService } from 'src/app/common/services/mydiet-common.service';
import { facilityListModel } from 'src/app/common/models/facilitylist.model';

@Component({
  selector: 'app-view-diet-manuals',
  templateUrl: './view-diet-manuals.component.html',
  styleUrls: ['./view-diet-manuals.component.scss']
})
export class ViewDietManualsComponent implements OnInit {
  public liveManuals: LiveManualResponse;
  public manualId: string;
  public selectedMenu: string;
  public selectedSubMenu: string;
  public selectedSubMenuId: string;
  public loadingComp: boolean = false;
  public manualName: string;
  public manualTypeName: string;
  public manualType: number;
  public searchText: string;
  public selectedFacility: facilityListModel;
  public isSubscribed: boolean = false;

  constructor(private toastrService: ToastrService,
    private commonService: MyDietCommonService) { }

  @BlockUI() blockUI: NgBlockUI;

  ngOnInit(): void {

    if (this.commonService.facilityListData && this.commonService.facilityListData.length > 0) {
      const hasActiveSubscription = this.commonService.facilityListData.some(facility =>
        facility.subscriptionStartDate && facility.isSubscriptionActive
      );
      if (hasActiveSubscription) {
        this.isSubscribed = true;
        this.getActiveManaul();
      }
      else {
        this.isSubscribed = false;
      }
    }
    else {
      this.isSubscribed = true;
    }
  }

  private getActiveManaul(): void {
    this.blockUI.start('Loading Active Manual...');
    this.commonService.getActiveManual().subscribe((response: any) => {
      if (response && response.data && response.data.id) {
        this.getToc(response.data.id);
      }
      else {
        this.loadingComp = true;
        this.toastrService.error('No active manual found');
      }
      this.blockUI.stop();
    },
      (error: any) => {
        this.loadingComp = true;
        this.toastrService.error('No active manual found');
        this.blockUI.stop();
      })
  }

  private getToc(manualId: Number): void {
    this.blockUI.start('Loading Table of Contents...');
    this.commonService.getLiveManualToC(manualId).subscribe((response: LiveManualResponse) => {
      if (response && response.data && response.data.id) {
        this.manualId = manualId.toString();
        this.manualName = response.data.manualName;
        this.manualType = response.data.manualType;
        this.manualTypeName = "Diet Manual - " + this.commonService.getManualType(response.data.manualType);
        this.liveManuals = response;
        this.selectedSubMenuId = this.liveManuals.data.manualDocumentModel[0].manualToc[0].id.toString();
        this.selectedMenu = this.liveManuals.data.manualDocumentModel[0].sectionName;
        this.selectedSubMenu = this.liveManuals.data.manualDocumentModel[0].manualToc[0].name;
        this.blockUI.stop();
      }
      else {
        this.loadingComp = true;
        this.blockUI.stop();
        this.toastrService.error('No manual found');
      }
    },
      (error: any) => {
        this.blockUI.stop();
        this.toastrService.error('No manual found');
      })
  }

  onMenuSelected(menu: any): void {
    this.selectedMenu = menu.title;
  }

  onSubMenuSelected(subMenu: any): void {
    this.selectedSubMenu = subMenu.title;
    this.selectedSubMenuId = subMenu.contentId;
  }

  onSearchText(searchText: any): void {
    this.searchText = searchText;
    if (searchText) {
      this.blockUI.start('Searching...');
      this.commonService.getManualSearchResults(Number(this.manualId), searchText.toString().trim()).subscribe((response: any) => {
        if (response && response.data && response.data.length > 0) {
          const transformedData = this.commonService.transformSearchResultsToSections(response.data);
          const liveManualSearchedResponse: LiveManualResponse = {
            data: {
              id: Number(this.manualId),
              manualName: this.manualName,
              manualType: this.manualType,
              manualDocumentModel: transformedData
            },
            metaData: response.metaData,
            error: response.error
          }
          this.liveManuals = liveManualSearchedResponse;
          this.selectedSubMenuId = this.liveManuals.data.manualDocumentModel[0].manualToc[0].id.toString();
          this.selectedMenu = this.liveManuals.data.manualDocumentModel[0].sectionName;
          this.selectedSubMenu = this.liveManuals.data.manualDocumentModel[0].manualToc[0].name;
        }
        else {
          this.liveManuals = getEmptyLiveManualResponse();
          this.loadingComp = true;

          this.toastrService.error('No Search Results Found');
        }
        this.blockUI.stop();
      },
        (error: any) => {
          this.liveManuals = getEmptyLiveManualResponse();
          this.blockUI.stop();
          this.toastrService.error('Error Occured During Search.');
        })
    }
    else {
      this.getToc(Number(this.manualId));
    }
  }

}