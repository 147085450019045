import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { facilityListModel } from '../../models/facilitylist.model';
import { MyDietCommonService } from '../../services/mydiet-common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-facility-selector',
  templateUrl: './facility-selector.component.html',
  styleUrls: ['./facility-selector.component.scss']
})
export class FacilitySelectorComponent implements OnInit {

  public facilityModel?: string;
  @Input() facilityList: facilityListModel[] = [];
  public facilityDisplayList: any = [];
  private facilitySubscription: Subscription;

  constructor(private commonService: MyDietCommonService) { }

  ngOnInit(): void {
    if ((this.commonService.facilityListData && this.commonService.facilityListData.length > 0) || (this.facilityList && this.facilityList.length > 0)) {


      if (this.commonService.facilityListData && this.commonService.facilityList.length > 0) {
        this.facilityList = this.commonService.facilityListData;
      }
      else {
        this.commonService.facilityListData = this.facilityList;
      }

      this.facilityList.forEach(element => {
        this.facilityDisplayList.push({ costCenterId: element.costCenterId, displayName: element.costCenterId + " - " + element.name });
      });
      this.facilityModel = this.facilityDisplayList[0].costCenterId;
    }
    this.facilitySubscription = this.commonService.facilityChange$.subscribe(facility => {
      if (facility) {
        this.facilityModel = facility.costCenterId;
      }
      else {
        this.facilityModel = this.facilityDisplayList[0].costCenterId;
        this.commonService.facilityChange$.next(this.facilityList[0]);
      }
    });
  }

  facilityChange() {
    if (this.facilityModel) {
      let selectedModel = this.facilityList.filter(x => x.costCenterId === this.facilityModel);
      if (selectedModel && selectedModel.length > 0) {
        this.commonService.facilityChange$.next(selectedModel[0]);
      }
    }
  };

  ngOnDestroy() {
    if (this.facilitySubscription) {
      this.facilitySubscription.unsubscribe();
    }
  }
}
