import { Component, OnInit } from '@angular/core';
import { NewsService } from '../news.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MyDietRbacCustomService } from 'src/app/common/services/mydiet-rbac.service';
import { MyDietCommonService } from 'src/app/common/services/mydiet-common.service';
import { NewsEditorComponent } from '../news-editor/news-editor.component';
import { facilityListModel } from 'src/app/common/models/facilitylist.model';
import { newsModel } from 'src/app/common/models/news.model';
import * as moment from 'moment';

@Component({
  selector: 'app-facility-news',
  templateUrl: './facility-news.component.html',
  styleUrls: ['./facility-news.component.scss']
})
export class FacilityNewsComponent implements OnInit {

  constructor(private newsService: NewsService,
    private toastrService: ToastrService,
    private domSanitizer: DomSanitizer,
    private rbacCustomService: MyDietRbacCustomService,
    private commonService: MyDietCommonService,
    private modalService: NgbModal
  ) { }
  public sortNewsOptions: string[] = ['Newest on top', 'Oldest on top'];
  public sortNewsModel = 'Newest on top';

  public metaData: any;
  public hospNews: newsModel[];
  public hospNewsData: any;
  public selectedFacility: facilityListModel;
  public currentUserRole: string;
  public isUser: boolean = true;
  public pageSource: string = 'Facility News';
  public isValidRoleAndSubscribed: boolean = false;
  facilitySelectedHospNews: Subscription;
  isHospNewsAvailableForFacility: boolean = true;
  @BlockUI() blockUIU: NgBlockUI;

  ngOnInit(): void {
    let rbacObj = this.rbacCustomService.rbacCustomObj;
    if (rbacObj?.current_role && rbacObj.current_role.role_name) {
      this.currentUserRole = rbacObj.current_role.role_name;
    }

    if (this.currentUserRole === "User") {
      this.isUser = true;
    } else {
      this.isUser = false;
    }

    this.facilitySelectedHospNews = this.commonService.facilityChange
      .subscribe(facility => {
        if (facility && facility.costCenterId) {
          this.selectedFacility = facility;
          if (facility.isSubscriptionActive && facility.subscriptionStartDate && !this.isUser) {
            this.isValidRoleAndSubscribed = true;
          }
          else {
            this.isValidRoleAndSubscribed = false;
          }

          this.getAllHospitalNews(facility.id);
        }
      });
  }

  sortNewsChange() {
    if (this.sortNewsModel === "Newest on top") {
      this.hospNews.sort((a: any, b: any) => {
        const dateDiff = new Date(b.submittedDate).getTime() - new Date(a.submittedDate).getTime();
        if (dateDiff !== 0) {
          return dateDiff;
        }
        return b.id - a.id; // Sort by id in descending order if dates are equal
      });
    } else {
      this.hospNews.sort((a: any, b: any) => {
        const dateDiff = new Date(a.submittedDate).getTime() - new Date(b.submittedDate).getTime();
        if (dateDiff !== 0) {
          return dateDiff;
        }
        return b.id - a.id; // Sort by id in descending order if dates are equal
      });
    }
  }

  getAllHospitalNews(facilityId?: number) {
    this.blockUIU.start();
    this.newsService.getAllHospitalNews(facilityId).subscribe({
      next: (res) => {
        this.hospNewsData = res.data;
        if (res && res.data && res.data.length > 0) {
          this.assignHospiatlNewsData();
          this.isHospNewsAvailableForFacility = true;
        } else {
          this.isHospNewsAvailableForFacility = false;
          this.hospNews = [];
          this.blockUIU.stop();
        }
      },
      error: (error) => {
        console.error(error);
        this.isHospNewsAvailableForFacility = false;
        this.hospNews = [];
        this.blockUIU.stop();
        this.toastrService.error('Error loading facility news data.');
      }
    });
  }

  assignHospiatlNewsData() {
    let hospitalNewsArray: newsModel[] = [];
    let data = this.hospNewsData;
    for (let i = 0; i < data.length; i++) {
      let newsObj: newsModel = {
        id: data[i].id,
        title: data[i].title,
        content: this.domSanitizer.bypassSecurityTrustHtml(data[i].content),
        daywithMonth: this.newsService.getMonthNameFromDate(data[i].submittedDate),
        year: this.newsService.getYearFromDate(data[i].submittedDate),
        expiryDate: data[i].expiryDate,
        submittedDate: data[i].submittedDate
      }
      hospitalNewsArray.push(newsObj);
      this.hospNews = hospitalNewsArray;
    }
    this.blockUIU.stop();
    this.sortNewsChange();
  }

  addFacilityNews() {
    if (this.isValidRoleAndSubscribed) {
      let newsEntryModal = this.modalService.open(NewsEditorComponent, {
        backdrop: 'static',
        keyboard: false,
        size: 'lg'
      });

      newsEntryModal.componentInstance.selectedFacilityId = this.selectedFacility.id;
      newsEntryModal.componentInstance.pageTitle = 'Add Facility News Headline';
      newsEntryModal.componentInstance.pageAction = 'Add';
      newsEntryModal.componentInstance.id = 0;
      newsEntryModal.componentInstance.pageSource = this.pageSource;
      newsEntryModal.componentInstance.quillData = '';
      newsEntryModal.componentInstance.validRoleAndSubscribed = this.isValidRoleAndSubscribed;
      // newsEntryModal.componentInstance.expiryDate = new Date(new Date().setDate(new Date().getDate() + 60));

      newsEntryModal.result.then(res => {
        if (res) {
          this.getAllHospitalNews(this.selectedFacility.id);
        }
      });
    }
    else {
      this.toastrService.error('Selected Facility dont have valid subscription.');
    }

  }

  initiateEdit(selectedNews: any) {
    let newsEntryModal = this.modalService.open(NewsEditorComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    });

    newsEntryModal.componentInstance.selectedFacilityId = this.selectedFacility.id;
    newsEntryModal.componentInstance.pageTitle = 'Edit Facility News Headline';
    newsEntryModal.componentInstance.pageAction = 'Edit';
    newsEntryModal.componentInstance.id = selectedNews.id;
    newsEntryModal.componentInstance.pageSource = this.pageSource;
    newsEntryModal.componentInstance.validRoleAndSubscribed = this.isValidRoleAndSubscribed;
    newsEntryModal.componentInstance.quillData = this.domSanitizer.sanitize(SecurityContext.HTML, selectedNews.content)
    newsEntryModal.componentInstance.expiryDate = moment(selectedNews.expiryDate).format('YYYY-MM-DD');
    newsEntryModal.componentInstance.newsTitle = selectedNews.title;

    newsEntryModal.result.then(res => {
      if (res) {
        this.getAllHospitalNews(this.selectedFacility.id);
      }
    });
  }

  ngOnDestroy() {
    if (this.facilitySelectedHospNews) {
      this.facilitySelectedHospNews.unsubscribe();
    }
  }
}
