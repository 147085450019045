<div class="modal-header" style="background-color: #70c1c0; font-weight: 600;">
    <h4 class="custom-title">{{title}} of {{fileName}}</h4>
    <button mat-icon-button type="button" aria-label="Close" (click)="activeModal.dismiss(null)"
        style="border: none; background:transparent;margin:auto; right: 20px; position: absolute;">
        <i class="fas fa-times"></i>
    </button>
</div>

<div style="height: 70vh;" class="modal-body">
    <div style="height: 100%;" class="row">
        <div style="width: 100%;height: 100% !important;" class="pdf-viewer">
            <iframe #pdfIframe width="100%" height="100%" style="display: none;"></iframe>
        </div>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Close</button>&nbsp;&nbsp;
</div>