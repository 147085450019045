import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewsService } from '../news.service';
import { newsEditorModel } from 'src/app/common/models/news.model';
import * as moment from 'moment';
import { CommonModalComponent } from 'src/app/common/components/common-modal/common-modal.component';

@Component({
  selector: 'app-news-editor',
  templateUrl: './news-editor.component.html',
  styleUrls: ['./news-editor.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class NewsEditorComponent implements OnInit {
  @Input() selectedFacilityId: number;
  @Input() pageAction: string = 'Add';
  @Input() id: number = 0;
  @Input() pageSource: string = 'System Administrator';
  @Input() pageTitle: string = 'Add System News';
  @Input() quillData: any;
  @Input() newsTitle: any;
  @Input() expiryDate: Date;
  @Input() validRoleAndSubscribed: boolean = false;
  public isEdit: boolean = false;
  public newsData: newsEditorModel = {
    id: 0,
    content: '',
    title: '',
    expiryDate: '',
    facilityId: 0
  };
  public isValidExpiryDate: boolean = true;
  public dataChanged: boolean = false;
  public selectedExpiryDate: Date;
  public minExpiryDate: Date = new Date();
  public maxExpiryDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() + 2));
  public quillDataAvailable: boolean = false;
  @BlockUI('manage-news') blockUI: NgBlockUI;
  @ViewChild('newsEditorDiv', { static: false }) newsEditorDiv: ElementRef;

  constructor(
    public ngbModal: NgbModal,
    private newsService: NewsService,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private renderer: Renderer2

  ) { }

  ngOnInit(): void {
    if (this.pageAction === 'Edit') {
      this.isEdit = true;
      this.quillDataAvailable = true;
      this.selectedExpiryDate = new Date(this.expiryDate);
    }
    else {
      this.isEdit = false;
    }
  }

  onQuillDataChange(event: any) {
    this.dataChanged = true;
    var regex = /(<([^>]+)>)/ig
    let hasText = !!event.replace(regex, "").trim().length;
    if (hasText) {
      this.quillDataAvailable = true;
    }
    else {
      this.quillDataAvailable = false;
    }
  }

  titleChanged() {
    this.dataChanged = true;
  }

  expiryDateChanged() {
    this.dataChanged = true;
  }

  invokeCancel() {
    if (this.dataChanged) {
      let modal = this.ngbModal.open(CommonModalComponent, {
        keyboard: false,
        backdrop: 'static'
      });

      modal.componentInstance.title = 'Cancel Confirmation';
      modal.componentInstance.bodyText = 'The changes made on the page will be lost. Please click Continue to proceed or Cancel to stay on the page.';
      modal.componentInstance.okText = 'Continue';

      this.renderer.setStyle(this.newsEditorDiv.nativeElement, 'opacity', '30%');

      return modal.result.then(() => {
        this.activeModal.close(null);
      }).catch(() => {
        this.renderer.setStyle(this.newsEditorDiv.nativeElement, 'opacity', '100%');
      });
    } else {
      this.activeModal.close(null);
      return true;
    }
  }

  saveData() {
    if (this.validRoleAndSubscribed) {
      this.blockUI.start();
      const today = moment().startOf('day');
      this.isValidExpiryDate = true;
      if (!(moment(this.selectedExpiryDate).isSameOrAfter(today))) {
        this.isValidExpiryDate = false;
      }
      if (moment(this.selectedExpiryDate).isAfter(moment().add(2, 'years'))) {
        this.isValidExpiryDate = false;
      }
      if (this.isValidExpiryDate) {
        const formData = new FormData();
        formData.append('id', this.id.toString());
        formData.append('content', this.quillData.toString());
        formData.append('title', this.newsTitle.toString());
        formData.append('expiryDate', moment(this.selectedExpiryDate).format('MM/DD/YYYY'));
        formData.append('facilityId', this.selectedFacilityId.toString());

        // this.newsData.id = this.id;
        // this.newsData.content = JSON.parse(JSON.stringify(this.quillData));
        // this.newsData.title = this.newsTitle;
        // this.newsData.expiryDate =   moment(this.selectedExpiryDate).format('MM/DD/YYYY');
        // this.newsData.facilityId = this.selectedFacilityId;
        if (this.pageSource === 'System News') {
          const systemNewsSubscription = this.pageAction === 'Add' ? this.newsService.addSystemNews(formData) : this.newsService.updateSystemNews(formData);
          systemNewsSubscription
            .subscribe(res => {
              this.blockUI.stop();
              this.activeModal.close('success');
              if (this.pageAction === 'Add') {
                this.toastrService.success('System News Added Successfully.');
              } else {
                this.toastrService.success('System News Updated Successfully.');
              }
            }, error => {
              this.blockUI.stop();
              console.error(error);
              this.toastrService.error('Error occured while saving the data.');
            });
        }
        else {
          const facilityNewsSubscription = this.pageAction === 'Add' ? this.newsService.addFacilityNews(formData) : this.newsService.updateFacilityNews(formData);
          facilityNewsSubscription
            .subscribe(res => {
              this.blockUI.stop();
              this.toastrService.success(this.pageAction === 'Add' ? 'Facility News Added Successfully.' : 'Facility News Updated Successfully.');
              this.activeModal.close('success');
            }, error => {
              this.blockUI.stop();
              console.error(error);
              this.toastrService.error('Error occured while saving the data.');
            });
        }
      }
      else {
        this.blockUI.stop();
      }
    }
    else {
      this.toastrService.error('Selected Facility dont have valid subscription.');
    }

  }

  invokeDeleteModal() {
    if (this.validRoleAndSubscribed) {
      let deleteModal = this.ngbModal.open(CommonModalComponent, {
        backdrop: 'static',
        keyboard: false,
        size: 'sm'
      });

      deleteModal.componentInstance.title = 'Delete News';
      deleteModal.componentInstance.bodyText = 'Are you sure you want to delete "' + this.newsTitle + '"  news?';
      deleteModal.componentInstance.cancelText = 'No';
      deleteModal.componentInstance.okText = 'Yes';

      this.renderer.setStyle(this.newsEditorDiv.nativeElement, 'opacity', '30%');

      deleteModal.result.then(() => {
        this.deleteData();
      }, () => {
        console.info('Delete news modal closed.');
        this.renderer.setStyle(this.newsEditorDiv.nativeElement, 'opacity', '100%');
      });
    }
    else {
      this.toastrService.error('Selected Facility dont have Valid Subscription.');
    }
  }

  deleteData() {
    this.blockUI.start();
    if (this.pageSource === 'System News') {
      this.newsService.deleteSystemNews(this.id)
        .subscribe(res => {
          this.blockUI.stop();
          this.toastrService.success('System News Deleted Successfully');
          this.activeModal.close('success');
        }, error => {
          this.blockUI.stop();
          this.renderer.setStyle(this.newsEditorDiv.nativeElement, 'opacity', '100%');
          console.error(error);
          this.toastrService.error('Error occured while Deleting the System News.');
        });
    }
    else {
      this.newsService.deleteFacilityNews(this.id)
        .subscribe(res => {
          this.blockUI.stop();
          this.toastrService.success('Facility News Deleted Successfully');
          this.activeModal.close('success');
        }, error => {
          this.blockUI.stop();
          this.renderer.setStyle(this.newsEditorDiv.nativeElement, 'opacity', '100%');
          console.error(error);
          this.toastrService.error('Error occured while Deleting the Facility News.');
        });
    }
  }
}
