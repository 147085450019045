<div *ngIf="isSubscribed">
  <div class="row" *ngIf="manualId" style="width: 100% !important;">
    <div class="col-12">
      <h1 style="margin-top: 0 !important;margin-bottom: 10px !important;" class="manual-name">{{ manualTypeName }}</h1>
    </div>

    <div class="col-sm-6 col-md-4 col-lg-4 screen-view">
      <app-left-menu [liveManualResponse]="liveManuals" (searchTextInitiated)="onSearchText($event)" (subMenuSelected)="onSubMenuSelected($event)"
        (menuSelected)="onMenuSelected($event)"></app-left-menu>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-6 screen-view">

      <app-right-content [contentId]="selectedSubMenuId" [selectedMenu]="selectedMenu"
        [selectedSubMenu]="selectedSubMenu" [searchText]="searchText" [manualId]="manualId"></app-right-content>
    </div>
  </div>
  <div *ngIf="!manualId && loadingComp">
    <div class="row">
      <h1>No Active Manual Found. Please Contact Administrator.</h1>
    </div>
  </div>
</div>

<div class="kt-portlet__body" *ngIf="!isSubscribed">
  <div class="alert alert-outline-warning">
    <strong>Warning!</strong>&nbsp;&nbsp; You are not subscribed to this feature. Please contact your System Administrator
  </div>
</div>