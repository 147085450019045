import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from './common/components/signup/signup.component';
import { HomeUnauthorizationComponent } from './home/home-unauthorization/home-unauthorization.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    {
        path: 'selfService',
        loadChildren: () => import('./common/components/pre-login/pre-login.module').then(m => m.PreLoginModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        data: { pageTitle: 'Home' }
    },
    {
        path: 'approvals',
        loadChildren: () => import('./approvals/new-user-approvals/user-approvals.module')
            .then(m => m.UserApprovalModule),
        data: { pageTitle: 'New User Registration Approvals ' }
    },
    {
        path: 'signup',
        component: SignupComponent,
        data: { pageTitle: null }
    },
    {
        path: 'administration',
        loadChildren: () => import('./administration/administration.module')
            .then(m => m.AdministrationModule),
        data: { pageTitle: 'Administration' }
    },
    {
        path: 'dietmanuals',
        loadChildren: () => import('./manuals/manuals.module').then(m => m.ManualsModule),
        data: { pageTitle: 'Diet Manuals' }
    },
    {
        path: 'userpatienteducation',
        loadChildren: () => import('./patient-education/patient-education.module').then(m => m.PatientEducationModule),
        data: { pageTitle: 'Patient Education' }
    },
    {
        path: 'calculations',
        loadChildren: () => import('./calculations/calculations.module').then(m => m.CalculationsModule),
        data: { pageTitle: 'Calculations' }
    },
    {
        path: 'mynutritionresources',
        loadChildren: () => import('./nutrition-resources/nutrition-resources.module').then(m => m.NutritionResourcesModule),
        data: { pageTitle: 'Nutrition Resources' }

    },
    {
        path: 'unauthorized',
        component: HomeUnauthorizationComponent,
        data: { pageTitle: null }
    },
    { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
